import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {fadeInAnimation} from "../../../../animations";
import {
  fadeInOnEnterAnimation,
  fadeOutOnLeaveAnimation,
  rotateInUpRightOnEnterAnimation,
  rotateOutDownRightOnLeaveAnimation
} from "angular-animations";
import {User} from "../../../../models/user.model";

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  animations: [
    fadeInOnEnterAnimation({duration: 250}),
    fadeOutOnLeaveAnimation({duration: 250}),
    rotateInUpRightOnEnterAnimation({duration: 400}),
    rotateOutDownRightOnLeaveAnimation({duration: 400})
  ]
})
export class UserMenuComponent implements OnInit {

  @Input() isUserAuthenticated: boolean | undefined | null;
  
  @Input() userData: User | undefined | null;

  @Output() loginEvent: EventEmitter<void>;

  @Output() logoutEvent: EventEmitter<void>;

  /**
   * If menu is visible
   * @type {boolean}
   */
  public isMenuVisible: boolean = false;

  public isGoogleBtnVisible: boolean = false;

  constructor() {
    this.loginEvent = new EventEmitter<void>()
    this.logoutEvent = new EventEmitter<void>()
  }

  ngOnInit(): void {}

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: any) {
    const path = event.path || (event.composedPath && event.composedPath())
    this.isMenuVisible = !!path.find((item: any) => item.id === 'user-card' || item.id === 'user-menu' && !this.isMenuVisible);
    this.isGoogleBtnVisible = !!path.find((item: any) => item.id === 'login-btn');
  }

  loginHandler() {
    this.loginEvent.emit();
  }

  logOutHandler() {
    this.logoutEvent.emit();
  }

  showGoogleBtn() {
    this.isGoogleBtnVisible = true;
  }

}
