<h6 class="text-center my-3">Enter the e-mail that was used for registration</h6>
<form class="w-100 my-3" [formGroup]="form">
  <div class="form-group">
    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <div class="input-group-text"><img src="./assets/images/user.png" alt="" class="img-responsive"></div>
      </div>
      <input formControlName="email" type="email" class="form-control" id="forgotEmailGroup" placeholder="Email">
    </div>
    <mat-error *ngIf="email?.hasError('email') && !email?.hasError('required')">
      Please enter a valid email address
    </mat-error>
    <mat-error *ngIf="email?.hasError('required') && email?.touched" >
      Email is <strong>required</strong>
    </mat-error>
  </div>
  
  <button type="submit" class="btn btn-default w-100  " [disabled]="form.invalid || (isLoading | async)" (click)="captchaRef.execute()"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="(isLoading | async)"></span> Send</button>
</form>

<p class="text-center">Back to <a routerLink="/sign-in">Sign In</a></p>

<re-captcha size="invisible" #captchaRef (resolved)="$event && sendEmailForReset($event)"
            [siteKey]="SITE_ID_NG">
</re-captcha>
