<div class="container-fluid">
  <div class="card">
    <div class="card-body">
      <div class="not-found-wrapper">
        <img src="./assets/images/error-404.svg" alt="404 Page Not Found" class="not-found-image">
        <h2>Opps! Why are you here?</h2>
        <p class="not-found-text">It looks like you've tried to access a page that has either been deleted or never existed. Please head back to the Homepage to keep browsing.</p>
        <div>
          <a href="/" class="btn btn-default">Back to Homepage</a>
        </div>
      </div>
    </div>
  </div>
</div>
