import {Action, createReducer, on} from "@ngrx/store";

import {User} from "../../models/user.model";
import {
  authenticate,
  authenticateError,
  authenticateOneTime,
  authenticateSuccess,
  authenticateThirdParty, authenticateThirdPartySuccess,
  sendEmailAction,
  sendEmailActionError,
  sendEmailActionSuccess,
  sendNewPassAction,
  sendNewPassActionError,
  sendNewPassActionSuccess,
  signOutSuccess,
  signUp,
  signUpError,
  signUpSuccess,
  updateUserInfo,
  updateUserInfoError,
  updateUserInfoSuccess
} from "./user.actions";

export interface UserState {
  loading: boolean;
  authenticated: boolean;
  user?: User;
}

const initialState: UserState = {
  loading: false,
  authenticated: false
}

const _userReducer = createReducer(
  initialState,
  on(authenticate, (state) => state = {...state, loading: true}),
  on(authenticateOneTime, (state) => state = {...state, loading: true}),
  on(authenticateThirdParty, (state) => state = {...state, loading: true}),
  on(authenticateSuccess, (state, userData: {token: string, user: User}) => state = {loading: false, authenticated: true, user: userData.user}),
  on(authenticateThirdPartySuccess, (state, userData: {token: string, user: User}) => state = {loading: false, authenticated: true, user: userData.user}),
  on(authenticateError, (state, {message}) => state = {...state, loading: false, authenticated: false}),
  on(signOutSuccess, state => state = {...state, authenticated: false, user: undefined}),
  on(signUp, state => state = {...state, loading: true}),
  on(signUpSuccess, (state, userData: {token: string, user: User}) => state = {loading: false, authenticated: false, user: userData.user}),
  on(signUpError, state => state = {...state, loading: false}),
  on(sendEmailAction, state => state = {...state, loading: true}),
  on(sendEmailActionSuccess, state => state = {...state, loading: false}),
  on(sendEmailActionError, state => state = {...state, loading: false}),
  on(updateUserInfo, (state) => state = {...state, loading: true}),
  on(updateUserInfoSuccess, (state, userData: {user: User}) => state = {...state, loading: false, user: userData.user}),
  on(updateUserInfoError, (state, {message}) => state = {...state, loading: false})
)

export function userReducer(state: UserState | undefined, action: Action) {
  return _userReducer(state, action)
}
