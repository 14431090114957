import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AddPayment} from "../../core/models/add-payment.model";
import {Observable} from "rxjs";
import {User} from "../../core/models/user.model";
import {Balance} from "../../core/models/balance.model";

@Injectable()
export class AccountApi {
	/**
	 * Constructor for AccountApi service
	 * @param {HttpClient} httpClient
	 */
	constructor(private httpClient: HttpClient) { }
	
	/**
	 * Add Payment System
	 * @param {string} payment_system
	 * @param {string} payoneer_email
	 * @param {string} paypal_email
	 * @param {string} wm_number
	 * @param {string} btc_number
	 * @param {string} qiwi_number
	 * @param {string} skrill_account
	 * @param {string} payeer_number
	 * @param {string} trc20_number
	 * @return {Observable<{data: AddPayment}>}
	 */
	addPaymentSystem(payment_system:string, payoneer_email?:string,paypal_email?: string, wm_number?:string, btc_number?:string, qiwi_number?:string, skrill_account?:string, payeer_number?: string, trc20_number?: string): Observable<{ data: AddPayment }> {
		const formData = {
			payment_system,
			payoneer_email,
			paypal_email,
			wm_number,
			btc_number,
			// qiwi_number,
			skrill_account,
			payeer_number,
			trc20_number
		}
		return this.httpClient.post<{ data: AddPayment }>('monetizer_api/api/user/payout_settings/set', formData)
	}
	
	getPaymentSystem():Observable<{data: AddPayment}> {
		return this.httpClient.get<{data: AddPayment}>('monetizer_api/api/user/payout_settings/get')
	}
	
	createChangePaymentSystemRequest(payment_system:string, payoneer_email?:string,paypal_email?: string, wm_number?:string,
	                                 btc_number?:string, qiwi_number?:string, skrill_account?:string, payeer_number?: string, trc20_number?: string,
	                                 bank_acc_number?:string, bank_currency?:string, bank_swift?:string): Observable<{ data: AddPayment }> {
		const formData = {
			payment_system,
			payoneer_email,
			paypal_email,
			wm_number,
			btc_number,
			// qiwi_number,
			skrill_account,
			payeer_number,
			trc20_number,
			bank_acc_number,
			bank_currency,
			bank_swift
		}
		return this.httpClient.post<{ data: AddPayment }>('monetizer_api/api/user/payout_settings/change', formData)
	}
	
	confirmPaymentChanges(token: string): Observable<{ data: { status: string} }> {
		const postData = {
			confirm_token: token
		};
		return this.httpClient.post<{data: {status: string}}>('monetizer_api/api/user/payout_settings/confirm_changes', postData);
	}
	
	/**
	 * Change pass
	 * @param {string} old_password
	 * @param {string} password
	 * @param {string} password_confirmation
	 * @return {Observable<{ status: string; message: string }>}
	 */
	
	changePassword(old_password: string, password: string, password_confirmation: string): Observable<{ status: string; message: string }> {
		const postData = {
			old_password,
			password,
			password_confirmation
		}
		return this.httpClient.post<{ status: string; message: string }>('monetizer_api/api/user/change_password', postData)
	}
	
	updateUser(name: string): Observable<{ data: { user: User} }> {
		const postData = {
			name
		};
		return this.httpClient.post<{data: {user: User}}>('monetizer_api/api/user/profile/set', postData);
	}
	
	deleteUser(): Observable<{ success: boolean }> {
		return this.httpClient.delete<{ success: boolean }>('monetizer_api/api/user/delete');
	}
	
	getBalanceData(): Observable<{data: Balance}> {
		return this.httpClient.get<{data: any}>('monetizer_api/api/app_user/get_balance');
	}
	
	getMinBankTransferAmount(): Observable<{min_amount: number}> {
		return this.httpClient.get<{min_amount: number}>('monetizer_api/api/payments/get_min_bank_transfer_amount');
	}
}
