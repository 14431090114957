import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {StoreModule} from "@ngrx/store";

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {environment} from "../environments/environment";
import {reducers} from "./core/store/app.reducer";
import {HeaderModule} from "./core/modules/header/header.module";
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import {LeftMenuModule} from "./core/modules/left-menu/left-menu.module";
import {EffectsModule} from "@ngrx/effects";
import {UserEffects} from "./core/store/user/user.effect";
import {AuthModule} from './auth/auth.module';
import {AuthGuard} from "./auth/guards/auth.guard";
import {HttpClientModule} from "@angular/common/http";
import {AuthApi} from "./auth/api/auth.api";
import {AlertModule} from "./core/modules/parts/alert/alert.module";
import {AccountApi} from "./account/api/account.api";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
      BrowserModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    HeaderModule,
    LeftMenuModule,
    AuthModule,
    AppRoutingModule,
    HttpClientModule,
    AlertModule,
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot([UserEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    })
  ],
  providers: [AuthApi, AuthGuard, AccountApi],
  bootstrap: [AppComponent]
})
export class AppModule {
}
