import {Component, EventEmitter, Input, OnInit, Output, ɵmarkDirty as markDirty} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {environment} from "../../../../environments/environment";
import {
  fadeInLeftOnEnterAnimation,
} from "angular-animations";


@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss'],
  animations: [
    fadeInLeftOnEnterAnimation({anchor: 'enter', duration: 500})
  ]
})
export class LeftMenuComponent implements OnInit {
  
  /**
   * Check if left menu is shown for user
   * @type {boolean}
   */
  @Input() isShown!: boolean;
  
  /**
   * Emmit event to toggle left menu
   * @type {EventEmitter<void>}
   */
  @Output() menuToggle: EventEmitter<void> = new EventEmitter<void>();

  env = environment;
  constructor(public router: Router) {
    this.router.events
      .subscribe(val => {
        if (val instanceof NavigationEnd)
          markDirty(this);
    })
  }

  ngOnInit(): void {
  }
  
  /**
   * Emmit event to toggle left menu on module link click
   * @return {void}
   */
  menuItemClickHandler(): void {
    if (window.innerWidth < 1200)
      this.menuToggle.emit();
  }
}
