import {Injectable} from '@angular/core';
import {CookieService} from "./cookie.service";

@Injectable({
  providedIn: 'root'
})
export class GaService {

  gaInstance: any;

  constructor(private cookieService: CookieService) {
  }

  initGa(){
    if ((window as any).ga && (window as any).ga.getAll) {
      this.gaInstance = (window as any).ga;
    }
  }

  getGaCid(){
    if(!this.gaInstance) this.initGa();

    let ga_cid;
    if(this.gaInstance){
      const tracker = this.gaInstance.getAll()[0];
      ga_cid = tracker.get('clientId');
    }

    if(!ga_cid){
      const _ga = this.cookieService.getCookie('_ga');
      ga_cid = _ga.split('.').slice(-2).join(".");
    }

    return ga_cid;
  }

  gaRegisterSuccess(){
    if(!this.gaInstance) this.initGa();

    if(this.gaInstance){
      this.gaInstance('send', 'event', 'Form','Submit');
    }
  }

  gaAuthSuccess(userId: number, firstLogin: boolean = false){
    if(!this.gaInstance) this.initGa();

    if(this.gaInstance) {
      this.gaInstance('set', 'userId', userId);
      this.gaInstance('send', 'event', 'authentication', 'user-id available');
    }

    if(firstLogin)
      this.setFirstVisitScript();
  }

  setFirstVisitScript() {
    const scriptElement = document.createElement('script');
    scriptElement.id = 'first-visit-script';
    scriptElement.innerHTML = `window.dataLayer = window.dataLayer || [ ]; dataLayer.push({'event': 'firstLogin'});`;
    document.getElementsByTagName('body')[0].appendChild(scriptElement);
  }

}
