<monetizer-alert [type]="typeOfMessage" [message]="message"></monetizer-alert>
<h2 class="text-center my-3">Log In</h2>
<form class="w-100 my-3" [formGroup]="form">
  <div class="form-group">
    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <div class="input-group-text"><img src="./assets/images/user.png" alt="" class="img-responsive"></div>
      </div>
      <input formControlName="email" type="email" class="form-control" id="loginEmailGroup" placeholder="Email">
    </div>
    <mat-error *ngIf="email?.hasError('email') && !email?.hasError('required')">
      Please enter a valid email address
    </mat-error>
    <mat-error *ngIf="email?.hasError('required') && email?.touched" >
      Email is <strong>required</strong>
    </mat-error>
  </div>
  <div class="form-group">
    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <div class="input-group-text"><img src="./assets/images/lock.png" alt="" class="img-responsive"></div>
      </div>
      <input formControlName="password" type="password" class="form-control" id="loginPasswordGroup" placeholder="Password">
    </div>
    <div class="text-right">
      <a routerLink="/forgot-pass" >Forgot password</a>
    </div>
  </div>
  
  
  <button type="submit" class="btn btn-primary w-100  " [disabled]="form.invalid || (isLoading | async)" (click)="captchaRef.execute()"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="(isLoading | async)"></span> Log In</button>
  
</form>
<div class="d-flex flex-column align-items-center">
  <div class="both-lined"><p>or use</p></div>
  <div class="social-container">
    <a (click)="goToThirdParty('google')" class="social"><img src="./assets/images/google.svg" [width]="16"
                                                              alt="Google" title="Google"></a>
<!--    <a (click)="goToThirdParty('github')" class="social"><img src="./assets/images/github.svg" [width]="16"-->
<!--                                                              alt="GitHub" title="GitHub"></a>-->
  </div>
</div>

<p class="text-center">You don't have an account? <a routerLink="/sign-up">Register Here</a></p>

<re-captcha size="invisible" #captchaRef (resolved)="$event && loginUser($event)"
            [siteKey]="SITE_ID_NG">
</re-captcha>
