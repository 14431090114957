export const environment = {
  production: true,
  apiUrl: 'https://data.traffmonetizer.com',
  logoUrl: './assets/images/logo-traffmonetizer.png',
  androidApp: "https://play.google.com/store/apps/details?id=com.traffmonetizer.client",
  iphoneApp: "https://app.traffmonetizer.com/",
  macosApp: "https://data.traffmonetizer.com/downloads/macos/traffmonetizer.dmg",
  windowsApp: "https://data.traffmonetizer.com/downloads/Installer.exe",
  linuxApp: "https://hub.docker.com/r/traffmonetizer/cli_v2",
  minPayout: 10,
  // v3
  //recaptchaKey: '6LeWeSUdAAAAAHjFO7EdJiy4WD6ikM9_v7wD9QHH',
  // v2
  recaptchaKey: '6LfA9xwhAAAAANZfwlGxKQu9LVrnwCWVHnH_uxg5',
  fingerprintPublicKey: 'hG8fTsCaFnRkBv1wFuRI'
};
