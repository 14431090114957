import {Component} from '@angular/core';
import {Store} from "@ngrx/store";
import {UserState} from "./core/store/user/user.reducer";
import {Observable} from "rxjs";
import {authenticateSuccess} from "./core/store/user/user.actions";
import {isUserAuthenticated} from "./core/store/user/user.selector";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  /**
   * Check if left menu is opened
   * @type {boolean}
   */
  isLeftMenuShown: boolean = false;
  
  title = 'Traffmonitizer';
  authenticated: boolean = false;

  constructor(private store: Store<{user: UserState}>) {
    this.store.select(isUserAuthenticated).subscribe(auth => {
      this.authenticated = auth;
    })
    const user = JSON.parse(<string>localStorage.getItem('user'));
    const token = localStorage.getItem('token');
    if (token && user) {
    
      this.store.dispatch(authenticateSuccess({user,token}))
    }
  }
  
  /**
   * Show/hide left menu toggle handler
   * @return {void}
   */
  menuToggle(): void {
    this.isLeftMenuShown = !this.isLeftMenuShown;
  }
}
