import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {MatInputModule} from "@angular/material/input";
import {ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDialogModule} from '@angular/material/dialog';
import {ErrorVerifyComponent} from './containers/sign-in/error-verify/error-verify-component.component';
import {SuccessVerifyComponent} from './containers/sign-in/success-verify/success-verify-component.component'

import {containers} from "./containers";
import {AuthFacade} from "./auth.facade";
import {RequestInterceptorService} from "../core/services/app.interceptor";
import {AuthRoutingModule} from "./auth-routing.module";
import {RecaptchaModule} from 'ng-recaptcha';
import {AlertModule} from "../core/modules/parts/alert/alert.module";
import {FingerprintjsProAngularModule} from "@fingerprintjs/fingerprintjs-pro-angular";
import {environment} from "../../environments/environment";
// import { EmailConfirmComponent } from './components/email-confirm/email-confirm.component';



@NgModule({
  declarations: [
    ...containers,
    ErrorVerifyComponent,
    SuccessVerifyComponent
    // EmailConfirmComponent,
  ],
	imports: [
      CommonModule,
      HttpClientModule,
      ReactiveFormsModule,
      AuthRoutingModule,
      MatInputModule,
      MatButtonModule,
      MatSnackBarModule,
      MatDialogModule,
      RecaptchaModule,
      AlertModule,
      FingerprintjsProAngularModule.forRoot({
        loadOptions: {
          apiKey: environment.fingerprintPublicKey,
          // endpoint: "https://app.traffmonetizer.com"
        }
      }),
    ],
  providers: [
    AuthFacade,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true
    }
  ]
})
export class AuthModule { }
