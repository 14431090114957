<h2 class="text-center my-3">Sign Up</h2>

<form class="w-100 my-3" [formGroup]="form">
  <div class="form-group">
    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <div class="input-group-text"><img src="./assets/images/lock.png" alt="" class="img-responsive"></div>
      </div>
      <input formControlName="name" type="text" class="form-control" id="loginNameGroup" placeholder="Your name">
    </div>
    <mat-error *ngIf="name?.hasError('minlength')">
      Min length of name <strong>2</strong> symbols
    </mat-error>
  </div>
  <div class="form-group">
    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <div class="input-group-text"><img src="./assets/images/user.png" alt="" class="img-responsive"></div>
      </div>
      <input formControlName="email" type="email" class="form-control" id="loginEmailGroup" placeholder="Email">
    </div>
    <mat-error *ngIf="email?.hasError('email') && !email?.hasError('required')">
      Please enter a valid email address
    </mat-error>
    <mat-error *ngIf="email?.hasError('required') && email?.touched">
      Email is <strong>required</strong>
    </mat-error>
  </div>
  <div class="form-group">
    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <div class="input-group-text"><img src="./assets/images/lock.png" alt="" class="img-responsive"></div>
      </div>
      <input formControlName="password" type="password" class="form-control" id="loginPasswordGroup" placeholder="Create password (8+ characters)">
    </div>
  </div>
  <div class="form-group">
    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <div class="input-group-text"><img src="./assets/images/lock.png" alt="" class="img-responsive"></div>
      </div>
      <input formControlName="passwordConfirm" type="password" class="form-control" id="loginPasswordConfirmGroup" placeholder="Repeat a password">
    </div>
  </div>
<!--  <div class="form-group" *ngIf="referralID">-->
<!--    <div class="input-group mb-2">-->
<!--      <div class="input-group-prepend">-->
<!--        <div class="input-group-text"><img src="./assets/images/auth-gift.png" alt="" class="img-responsive"></div>-->
<!--      </div>-->
<!--      <input formControlName="coupon" type="text" class="form-control" id="couponGroup" placeholder="Coupon Code">-->
<!--    </div>-->
<!--  </div>-->
<!--  <div *ngIf="referralID">-->
<!--    <div class="alert alert-success text-center mb-2" role="alert" >-->
<!--      <img src="./assets/images/auth-gift.png" class="icon-img mb-1 mr-1" alt=""> Referral link bonus<br/>-->
<!--      $5 will be added to your account-->
<!--    </div>-->
<!--&lt;!&ndash;    <button type="button" class="btn btn-link w-100" (click)="showCouponForm()">I want to use coupon code instead</button>&ndash;&gt;-->
<!--  </div>-->
  
  
  
  <!--		<div class="form-group form-check">-->
  <!--			<input type="checkbox" class="form-check-input" id="exampleCheck1">-->
  <!--			<label class="form-check-label" for="exampleCheck1">Check me out</label>-->
  <!--		</div>-->
  <button type="submit" class="btn btn-default w-100  " [disabled]="form.invalid || (isLoading | async)" (click)="captchaRef.execute()"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="(isLoading | async)"></span> Sign Up</button>
</form>
<div class="d-flex flex-column align-items-center">
  <div class="both-lined"><p>or use</p></div>
  <div class="social-container">
    <a (click)="goToThirdParty('google')" class="social"><img src="./assets/images/google.svg" [width]="16"
                                                              alt="Google" title="Google"></a>
<!--    <a (click)="goToThirdParty('github')" class="social"><img src="./assets/images/github.svg" [width]="16"-->
<!--                                                              alt="GitHub" title="GitHub"></a>-->
  </div>
</div>
<p class="text-center">Already have an account? <a routerLink="/sign-in">Log in</a></p>

<re-captcha size="invisible" #captchaRef (resolved)="$event && registerUser($event)"
            [siteKey]="SITE_ID_NG">
</re-captcha>
