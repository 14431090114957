<section class="auth nk-wrap">
  <div class="container">
    <div class="d-flex align-items-center flex-column justify-content-center vh-100">
      <div class="form-wrapper shadow-sm rounded">
        <img src="./assets/images/logo-traffmonetizer.png" alt="Traffmonitizer" class="img-responsive form-logo">
        
        <router-outlet></router-outlet>
      </div>
      <div class="mt-2">
      <p class="text-center"><a href="https://traffmonetizer.com/terms-of-service" rel="noopener noreferrer" target="_blank">Terms of Service</a> | <a href="https://traffmonetizer.com/privacy-policy" rel="noopener noreferrer" target="_blank">Privacy Policy</a> </p>
      </div>
    </div>
  </div>
</section>
