import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { fadeInDownOnEnterAnimation} from "angular-animations";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {getUserData, isUserAuthenticated} from "../../store/user/user.selector";
import {AppState} from "../../store/app.reducer";
import {authenticate, signOut} from "../../store/user/user.actions";
import {setDomainData} from "../../store/domain/domain.actions";
import {User} from "../../models/user.model";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    fadeInDownOnEnterAnimation({anchor: 'enter', duration: 500})
  ]
})
export class HeaderComponent implements OnInit {
  
  /**
   * Emmit event for left menu toggle
   * @type {EventEmitter<void>}
   */
  @Output() menuToggle: EventEmitter<void> = new EventEmitter<void>()

  isUserAuthenticated$: Observable<boolean>;
  
  userData$: Observable<User | undefined>;

  constructor(private store: Store<AppState>) {
    this.isUserAuthenticated$ = store.select(isUserAuthenticated)
    this.userData$ = store.select(getUserData);
  }

  ngOnInit(): void {
  }

  login() {
    // this.store.dispatch(authenticate())
  }

  logout() {
    this.store.dispatch(signOut())
  }

  saveDomainDataToState(domainData: {domain: string; domainTypeId: number}) {
    this.store.dispatch(setDomainData(domainData));
  }
  
  /**
   * Left menu toggle handler
   * @return {void}
   */
  menuToggleHandler(): void {
    this.menuToggle.emit()
  }
}
