import {Injectable} from "@angular/core";
import {HttpHandler, HttpInterceptor, HttpRequest,  HttpErrorResponse, HttpResponse} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Store} from "@ngrx/store";
import {AppState} from "../store/app.reducer";
import {catchError, map} from "rxjs/operators";
import {throwError} from "rxjs";
import {signOut} from "../store/user/user.actions";
import {MatSnackBar} from "@angular/material/snack-bar";
import {secondsToTimeString} from "../utils/utils";

@Injectable()
export class RequestInterceptorService implements HttpInterceptor {
  
  constructor(private store: Store<AppState>, private _snackBar: MatSnackBar) {
  }
  
  intercept(req: HttpRequest<any>, next: HttpHandler) {

    if (req.url.includes('monetizer_api')) {
      req = req.clone({url: req.url.replace('monetizer_api', `${environment.apiUrl}`)});
    }
  
    return next.handle(this.addToken(req))
    .pipe(
        map(response => {
          if (response instanceof HttpResponse) {
            const newToken = response.headers.get('Authorization')?.split('Bearer ')[1];
            if (newToken)
              localStorage.setItem('token', newToken)
          }
          return response
        }),
        catchError(error => {
          if (error instanceof HttpErrorResponse) {
            switch ((<HttpErrorResponse>error).status) {
              case 401:
                return this.handle401Error(error);
              // case 422:
              //   return this.handle422Error(error);
              case 429:
                return this.handle429Error(error);
              default:
                return throwError(error)
            }
          } else {
            return throwError(error);
          }
        })
    );
  }
  
  private addToken(req: HttpRequest<any>): HttpRequest<any> {
    const token = localStorage.getItem('token');
    return req.clone({setHeaders: {Authorization: 'Bearer ' + token}});
  }
  
  private handle401Error(error: HttpErrorResponse) {
    this.store.dispatch(signOut());
    return throwError(error)
  }
  
  private handle422Error(error: HttpErrorResponse) {
    const message = error.error?.message ? error.error.message : 'Error';
    this._snackBar.open(message, 'Close', {duration: 5000, panelClass: ['snack-bar-error']});
    return throwError(error)
  }
  
  private handle429Error(error: HttpErrorResponse) {
    let message = error.error?.message ? error.error.message : 'Error';
    if(error.headers.has('Retry-After') && parseInt(error.headers.get('Retry-After')?? '', 10) > 0) {
      const retrySeconds = parseInt(error.headers.get('Retry-After')?? '', 10);
      message += ' Retry in ' + secondsToTimeString(retrySeconds);
    }
    this._snackBar.open(message, 'Close', {duration: 5000, panelClass: ['snack-bar-error']});
    return throwError(error);
  }
}
