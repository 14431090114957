<div class="nk-header-search">
  <form [formGroup]="form" (submit)="onSubmit()">
    <ul class="nk-block-tools">
      <li>
        <div class="form-control-wrap mr-4">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Domain</mat-label>
            <input formControlName="domain" matInput>
          </mat-form-field>
<!--          <input formControlName="domain" type="text" class="form-control" id="default-04" placeholder="example.com">-->
        </div>
      </li>
      <li class="ml-3">
        <mat-form-field appearance="outline">
          <mat-label>Domain Type</mat-label>
          <mat-select formControlName="domainTypeId">
            <mat-optgroup *ngFor="let group of domainTypesGroup" [label]="group.name"
                          [disabled]="group.disabled">
              <mat-option *ngFor="let domainType of group.domainTypes" [value]="domainType.value">
                {{domainType.viewValue}}
              </mat-option>
            </mat-optgroup>
          </mat-select>
        </mat-form-field>

<!--        <div [class.show]="isSelectOpened" class="drodown">-->
<!--          <a class="dropdown-toggle btn btn-outline-light btn-white" data-toggle="dropdown">*.domain/*</a>-->
<!--          <div [class.show]="isSelectOpened" class="dropdown-menu dropdown-menu-right"-->
<!--               style="position: absolute;transform: translate3d(222px, 52px, 0px);top: 0px;left: 0px;will-change: transform;"-->
<!--               x-placement="bottom-end">-->
<!--            <ul class="link-list-opt no-bdr">-->
<!--              <li><a href="#"><span>*.domain/*</span></a>-->
<!--                domain with subdomains-->
<!--              </li>-->
<!--              <li><a href="#"><span>https://domain.com/</span></a>-->
<!--                URL-->
<!--              </li>-->
<!--              <li><a href="#"><span>domain/*</span></a>-->
<!--                domain without subdomains-->
<!--              </li>-->
<!--              <li><a href="#"><span>domain.com/path/*</span></a>-->
<!--                Prefix-->
<!--              </li>-->
<!--            </ul>-->
<!--          </div>-->
<!--        </div>-->
      </li>
    </ul>
  </form>
</div>
