import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  constructor() { }
  
  public getCookie(name: string) {
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;
    
    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }
  
  public deleteCookie(name: any) {
    this.setCookie(name, "", -1);
  }
  
  public setCookie(name: string, value: string, expireSeconds: number, path: string = "") {
    let d: Date = new Date();
    d.setTime(d.getTime() + expireSeconds * 1000);
    let expires: string = "expires=" + d.toUTCString();
    document.cookie = name + "=" + value + "; " + expires + (path.length > 0 ? "; path=" + path : "");
  }
  
  public getReferrerParams() {
    let referrer_params: any = {
      'referrer_params_1': null,
      'referrer_params_2': null,
      'referrer_params_3': null,
      'referrer_params_4': null,
      'referrer_params_5': null
    };
    for (let i = 1; i < 6; i++) {
      referrer_params['referrer_params_' + i] = this.getCookie('referrer_params_' + i);
    }
    
    return referrer_params;
  }
  
  public getReferrerRegister() {
    let referrer_register = this.getCookie('referrer_register');
    if (!referrer_register) referrer_register = document.referrer;
    
    return referrer_register;
  }

  public getReferrer() {
    return this.getCookie('referrer');
  }

  public getAllReferrerParams() {
    return {
      referrer_register: this.getReferrerRegister(),
      referrer: this.getReferrer(),
      referrer_params: this.getReferrerParams()
    };
  }
}






