import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {AuthFacade} from "../../auth.facade";
import {jackInTheBoxOnEnterAnimation} from "angular-animations";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {GaService} from "../../../core/services/ga.service";
import {FingerprintjsProAngularService} from "@fingerprintjs/fingerprintjs-pro-angular";

export function MustMatch(controlName: string, checkControlName: string): ValidatorFn {
  return (controls: AbstractControl) => {
    const control = controls.get(controlName);
    const checkControl = controls.get(checkControlName);

    if (checkControl?.errors && !checkControl.errors.matching) {
      return null;
    }

    if (control?.value !== checkControl?.value) {
      controls.get(checkControlName)?.setErrors({ matching: true });
      return { matching: true };
    } else {
      return null;
    }
  }
}

@Component({
  selector: 'monetizer-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss', '../sign-in/sign-in.component.scss'],
  encapsulation : ViewEncapsulation.None,
  animations: [
    jackInTheBoxOnEnterAnimation({duration: 500, delay: 100}),
  ]
})
export class SignUpComponent implements OnInit {
  
  isLoading!: Observable<boolean>;
  
  referralID: string|null = null
  /**
   * Recaptcha site key
   * @type {string}
   */
  public SITE_ID_NG = environment.recaptchaKey;
  /**
   * Register data form
   * @type {FormGroup}
   */
  form: FormGroup;

  /**
   * Getter for form name control
   * @type {AbstractControl}
   */
  get name(): AbstractControl | null {
    return this.form.get('name');
  }

  /**
   * Getter for form email control
   * @type {AbstractControl}
   */
  get email(): AbstractControl | null {
    return this.form.get('email');
  }

  /**
   * Getter for form password control
   * @type {AbstractControl}
   */
  get password(): AbstractControl | null {
    return this.form.get('password');
  }

  /**
   * Getter for form confirm password control
   * @type {AbstractControl}
   */
  get passwordConfirm(): AbstractControl | null {
    return this.form.get('passwordConfirm');
  }

  /**
   * Constructor for SignUpComponent
   * @type {AuthFacade} authFacade
   */
  constructor(private authFacade: AuthFacade, private gaService: GaService, private fingerprintService: FingerprintjsProAngularService) {
    this.form = new FormGroup({
      name: new FormControl('', [
        Validators.minLength(2)
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.email,
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(1000)
      ]),
      passwordConfirm: new FormControl('', [
      
      ])
    }, {validators: MustMatch('password', 'passwordConfirm')})
  }

  /**
   * Call on component init
   * @return {void}
   */
  ngOnInit(): void {
    this.referralID = (localStorage.getItem('referralID')) ? localStorage.getItem('referralID') : null;
    this.isLoading = this.authFacade.getLoadingState();
  }

  /**
   * Call action to register user
   * @return {void}
   */
  async registerUser(captcha:string): Promise<void> {
    if (this.form.valid){
      const ga_cid = this.gaService.getGaCid();
      const data = await this.fingerprintService.getVisitorData();
      const visitor_id = data.visitorId;
      this.authFacade.registerUser({...this.form.value, recaptcha: captcha, gaCid: ga_cid, visitorId: visitor_id});
    }
  }
  
  goToThirdParty(part: string) {
    this.authFacade.goToThirdParty(part).subscribe((url) => {
      window.location.href = url;
    })
  }
}
