<section class="left-menu">
  <div [@enter] class="nk-sidebar nk-sidebar-fixed is-light" [class.shown]="isShown" data-content="sidebarMenu">
    <div class="nk-sidebar-element nk-sidebar-head">
      <div class="nk-sidebar-brand">
        <a routerLink="dashboard" (click)="menuItemClickHandler()" class="logo-link nk-sidebar-logo">
          <img class="logo-dark logo-img" src="{{env.logoUrl}}" alt="logo-dark">
        </a>
      </div>
    </div>
    <div class="nk-sidebar-element">
      <div class="nk-sidebar-content">
        <div class="nk-sidebar-menu">
          <ul class="nk-menu">
            <li [class.active]="router.url.includes('dashboard')" class="nk-menu-item current-page">
              
              <a routerLink="dashboard" (click)="menuItemClickHandler()" class="nk-menu-link" title="Dashboard">
                <img src="./assets/images/dashboard-g.png" alt="" class="icon-img mr-1">
                <span class="nk-menu-text">Dashboard</span></a>
            </li>
            <li [class.active]="router.url.includes('payments')" (click)="menuItemClickHandler()" class="nk-menu-item current-page">
              <a routerLink="payments" class="nk-menu-link" title="Payments">
                <img src="./assets/images/payments-g.png" alt="" class="icon-img mr-1">
                <span class="nk-menu-text">Payments</span></a>
            </li>
            <li [class.active]="router.url.includes('referrals')" (click)="menuItemClickHandler()" class="nk-menu-item current-page">
    
              <a routerLink="referrals" class="nk-menu-link" title="Referrals">
                <img src="./assets/images/referrals-g.png" alt="" class="icon-img mr-1">
                <span class="nk-menu-text">Referrals</span></a>
            </li>
            <li [class.active]="router.url.includes('stats')" (click)="menuItemClickHandler()" class="nk-menu-item current-page">
    
              <a routerLink="stats" class="nk-menu-link" title="Stats">
                <img src="./assets/images/stats.png" alt="" class="icon-img mr-1">
                <span class="nk-menu-text">Stats</span></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
