<h6 class="text-center my-3">Enter your new password in the fields below</h6>

<form class="w-100 my-3" [formGroup]="form">
  <div class="form-group">
    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <div class="input-group-text"><img src="./assets/images/lock.png" alt="" class="img-responsive"></div>
      </div>
      <input formControlName="password" type="password" class="form-control" id="resetPasswordGroup" placeholder="Password">
    </div>
    <mat-error *ngIf="password?.hasError('minlength')">
      Min length of password <strong>8</strong>
    </mat-error>
    <mat-error *ngIf="password?.hasError('required') && password?.touched">
      Password is <strong>required</strong>
    </mat-error>
  </div>
  <div class="form-group">
    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <div class="input-group-text"><img src="./assets/images/lock.png" alt="" class="img-responsive"></div>
      </div>
      <input formControlName="passwordConfirm" type="password" class="form-control" id="resetPasswordConfirmGroup" placeholder="Repeat password">
    </div>
    <mat-error *ngIf="passwordConfirm?.hasError('required')">
      Password is <strong>required</strong>
    </mat-error>
    <mat-error *ngIf="passwordConfirm?.hasError('matching') && passwordConfirm?.touched">
      Password must <strong>matches</strong>
    </mat-error>
  </div>
  <button type="submit" class="btn btn-primary w-100  " [disabled]="form.invalid" (click)="captchaRef.execute()">Submit</button>
</form>

<p class="text-center">Back to <a routerLink="/sign-in">Sign In</a></p>

<re-captcha size="invisible" #captchaRef (resolved)="$event && sendNewPass($event)"
            [siteKey]="SITE_ID_NG">
</re-captcha>
