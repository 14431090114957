import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthGuard} from "./auth/guards/auth.guard";
import {NotFoundComponent} from "./core/modules/not-found/not-found.component";

const routes: Routes = [{
  path: 'dashboard',
  canActivate: [AuthGuard],
  loadChildren:() => import('./products/dashboard/dashboard.module').then(m => m.DashboardModule)
}, {
  path: 'referrals',
  canActivate: [AuthGuard],
  loadChildren:() => import('./products/referrals/referrals.module').then(m => m.ReferralsModule)
}, {
  path: 'payments',
  canActivate: [AuthGuard],
  loadChildren:() => import('./products/payments/payments.module').then(m => m.PaymentsModule)
}, {
  path: 'account',
  canActivate: [AuthGuard],
  loadChildren:() => import('./account/account.module').then(m => m.AccountModule)
}, {
  path: 'stats',
  canActivate: [AuthGuard],
  loadChildren:() => import('./products/stats/stats.module').then(m => m.StatsModule)
}, {
  path: '',
  redirectTo: 'dashboard',
  pathMatch: 'full'
}, {
  path: '**',
  redirectTo: '404'
}, {
  path: '404',
  component: NotFoundComponent
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
