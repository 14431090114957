import { createSelector } from '@ngrx/store';
import {AppState} from "../app.reducer";
import {UserState} from "./user.reducer";

export const selectUser = (state: AppState) => state.user;

export const getUserData = createSelector(
  selectUser,
  (state: UserState) => state.user
)

export const isUserAuthenticated = createSelector(
  selectUser,
  (state: UserState) => state.authenticated
)

export const isLoading = createSelector(
	selectUser,
	(state: UserState) => state.loading
)

export const getAffiliateLink = createSelector(
	selectUser,
	(state: UserState) => state.user?.affiliate_link
)

// export const isUserVerified = createSelector(
//   selectUser,
//   (state: UserState) => !!state.user?.verified
// )
