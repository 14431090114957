<section class="header nk-body bg-lighter npc-default has-sidebar no-touch">
  <div [@enter] class="nk-header nk-header-fixed is-light">
    <div class="container-fluid">
      <div class="nk-header-wrap">
<!--        <app-domain-control-->
<!--          (saveDomainDataToState)="saveDomainDataToState($event)">-->
<!--        </app-domain-control>-->
          <button mat-icon-button class="mobile-menu-icon" aria-label="Menu icon" (click)="menuToggleHandler()">
              <mat-icon>menu</mat-icon>
          </button>
        <div class="nk-header-tools">
          <app-user-menu
            [isUserAuthenticated]="isUserAuthenticated$ | async"
            [userData] = "userData$ | async"
            (loginEvent)="login()"
            (logoutEvent)="logout()">
          </app-user-menu>
        </div>
      </div>
    </div>
  </div>
</section>
