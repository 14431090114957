import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

interface DomainType {
  value: number;
  viewValue: string;
}

interface DomainTypeGroup {
  disabled?: boolean;
  name: string;
  domainTypes: DomainType[];
}

@Component({
  selector: 'app-domain-control',
  templateUrl: './domain-control.component.html',
  styleUrls: ['./domain-control.component.scss']
})
export class DomainControlComponent implements OnInit, OnDestroy {

  /**
   * Emmit event to save domain data to store
   * @type {EventEmitter<{domain: string, domainTypeId: number}>}
   */
  @Output() saveDomainDataToState: EventEmitter<{ domain: string; domainTypeId: number; }>;

  /**
   * Form group for domain data
   * @type {FormGroup}
   */
  public form: FormGroup;

  /**
   * Domain type group for select box
   * @type {DomainTypeGroup[]}
   */
  public domainTypesGroup: DomainTypeGroup[] = [{
    name: 'domain with subdomains',
    domainTypes: [{
      value: 0,
      viewValue: '*.domain/*'
    }]
  }, {
    name: 'URL',
    domainTypes: [{
      value: 1,
      viewValue: 'https://domain.com/'
    }]
  }, {
    name: 'domain without subdomains',
    domainTypes: [{
      value: 2,
      viewValue: 'domain/*'
    }]
  }, {
    name: 'Prefix',
    domainTypes: [{
      value: 3,
      viewValue: 'domain.com/path/*'
    }]
  }];

  /**
   * Subject to destroy all subscription
   * @type {Subject<void>}
   * @private
   */
  private destroy$: Subject<void>;

  constructor() {
    this.saveDomainDataToState = new EventEmitter<{ domain: string, domainTypeId: number }>();
    this.destroy$ = new Subject();

    this.form = new FormGroup({
      domain: new FormControl('dataforseo.com', [Validators.required]),
      domainTypeId: new FormControl(0, [Validators.required])
    })
  }

  ngOnInit(): void {
    this.form.get('domainTypeId')?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
      if (this.form.valid)
        this.saveDomainDataToState.emit({domain: this.form.value.domain, domainTypeId: value})
    })

  }

  onSubmit(): void {
    if (this.form.valid) {
      this.saveDomainDataToState.emit(this.form.value);
    }
  }

  /**
   * Destroy all components on component destroy
   * @return {void}
   */
  ngOnDestroy(): void {
    this.destroy$.next();
  }

}
